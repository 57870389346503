import { render, staticRenderFns } from "./ApplicationWidgetEditor.vue?vue&type=template&id=064de856&scoped=true"
import script from "./ApplicationWidgetEditor.vue?vue&type=script&lang=js"
export * from "./ApplicationWidgetEditor.vue?vue&type=script&lang=js"
import style0 from "./ApplicationWidgetEditor.vue?vue&type=style&index=0&id=064de856&prod&scoped=true&lang=scss"
import style1 from "./ApplicationWidgetEditor.vue?vue&type=style&index=1&id=064de856&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "064de856",
  null
  
)

export default component.exports