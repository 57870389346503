var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"widget-image-cards"},[_c('div',{staticClass:"vx-row match-height"},_vm._l((this.fileList),function(file,index){return _c('div',{key:index,staticClass:"vx-col mb-base md:w-1/6 sm:w-1/4 xs:w-full"},[_c('div',{staticClass:"scaling",style:({ backgroundImage: 'url(' + file.fullUrl + '?auto=compress&cs=tinysrgb&h=350)' , backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', cursor: 'pointer' }),on:{"click":function($event){return _vm.onFullScreen(file)}}})])}),0),_c('FsLightbox',{attrs:{"toggler":_vm.fullScreenToggle,"sources":_vm.imageListUrl,"slide":_vm.fullScreenCurrentIndex,"type":"image","types":[null, null, 'video'],"customToolbarButtons":[
      {
      viewBox: '0 0 24 24',
      d: 'M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.25 17.292l-4.5-4.364 1.857-1.858 2.643 2.506 5.643-5.784 1.857 1.857-7.5 7.643z',
      width: '17px',
      height: '17px',
      title: 'Select File',
      onClick: _vm.onSelectedFile
      }
    ]}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }